import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Fade, Popper, Stack } from '@mui/material'
import { ArrowDropDownRounded } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import './NavBar.css'
import { PaperDropdown } from '../../styles'

interface IMenuHorizontalButtonsProps {
  navigationTitle: string
  navigationURL?: string
  navigationCY?: string
  navigationDropdown?: INavigationDropdownProps[]
}

interface INavigationDropdownProps {
  title: string
  url: string
  dataCy?: string
}

export default function MenuHorizontalButtons({
  navigationURL,
  navigationTitle,
  navigationCY,
  navigationDropdown
}: IMenuHorizontalButtonsProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const location = useLocation()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        disableTouchRipple
        className={`nav-btn ${navigationURL && location.pathname.startsWith(navigationURL) && 'nav-selected'} ${open && 'nav-dropdown-selected'}`}
        component={navigationURL ? Link : 'button'}
        to={navigationURL}
        data-cy={navigationCY}
        endIcon={
          navigationDropdown && (
            <ArrowDropDownRounded
              className='dropdown-arrow'
              sx={{ ml: -1, transform: open ? 'rotate(180deg)' : 'none' }}
            />
          )
        }
        ref={navigationDropdown && anchorRef}
        onMouseOver={navigationDropdown && handleOpen}
        onMouseOut={navigationDropdown && handleClose}
      >
        <FormattedMessage id={navigationTitle} />
      </Button>
      {navigationDropdown && (
        <Popper
          onMouseOver={handleOpen}
          onMouseOut={handleClose}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade in={open} {...TransitionProps} style={{ transformOrigin: placement }}>
              <PaperDropdown>
                <Stack>
                  {navigationDropdown.map((dropdown, index) => (
                    <Button
                      key={index}
                      disableTouchRipple
                      className='nav-dropdown'
                      onClick={handleClose}
                      component={Link}
                      to={dropdown.url}
                      data-cy={dropdown.dataCy}
                    >
                      <FormattedMessage id={dropdown.title} />
                    </Button>
                  ))}
                </Stack>
              </PaperDropdown>
            </Fade>
          )}
        </Popper>
      )}
    </>
  )
}
