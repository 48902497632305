import { combineReducers } from 'redux'
import ChangeLanguage from './changeLanguage'
import { dataContactReducer } from './dealersAndAgentsReducer'
import { dataCountriesReducer } from './dataCountriesReducer'
import accountReducer from './accountReducer'
import historyReducer from './lastHistoryPath'
import { dataBannersReducer } from './bannerReducer'
import stripeAccountReducer from './stripeAccountReducer'
import { updatesReducer } from './updatesReducer'
import { notificationsReducer } from './notificationsReducer'
import { educationalPartnersReducer } from './educationalPartnersReducer'
import modalReducer from './modalReducer'
import { stripeProductsReducer } from './stripeProductsReducer'

const rootReducer = combineReducers({
  changeLanguage: ChangeLanguage,
  dataContactReducer: dataContactReducer,
  accountReducer,
  historyReducer,
  dataBannersReducer,
  notificationsReducer,
  educationalPartnersReducer,
  dataCountriesReducer,
  stripeAccountReducer,
  updatesReducer,
  modalReducer,
  stripeProductsReducer
})

export default rootReducer
