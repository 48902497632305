import * as React from 'react'
import { IconButton, Drawer } from '@mui/material'
import { Menu } from '@mui/icons-material'
import MenuHamburgerList from './MenuHamburgerList'

export default function MenuHamburger() {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <IconButton className='nav-btn' onClick={() => setOpen(true)}>
        <Menu />
      </IconButton>
      <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
        <MenuHamburgerList open={open} setOpen={setOpen} />
      </Drawer>
    </>
  )
}
