import React from 'react'
import './Footer.css'
import { withStyles } from '@mui/styles'
import { AccordionDetails, AccordionSummary, Accordion, Typography, Stack, Box } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const AccordionPanel = withStyles(() => ({
  root: {
    textAlign: 'left',
    width: '100%',
    maxWidth: 1080,
    margin: '0 !important' /* To avoid movement while expanding*/,
    background: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #444',
    borderRadius: 0,
    padding: 0,
    '&:nth-child(1)': {
      borderTop: '1px solid #444'
    }
  }
}))(Accordion)

const AccordionPanelSummary = withStyles(() => ({
  root: {
    padding: 0,
    minHeight: '48px !important',
    expanded: {
      minHeight: '48px !important'
    }
  },
  content: {
    margin: '0 !important',
    expanded: {
      margin: '0 !important'
    }
  }
}))(AccordionSummary)

const AccordionPanelDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: 0,
    paddingBottom: '1rem'
  }
}))(AccordionDetails)

const FooterMenuMobile = () => {
  const [expanded, setExpanded] = React.useState('')
  const handleChange = (newExpanded: string) => setExpanded(newExpanded !== expanded ? newExpanded : '')

  return (
    <Box py={1} className='footernav-mobile'>
      <AccordionPanel expanded={expanded === 'panel1'} onChange={() => handleChange('panel1')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel1d-content'
          id='panel1d-header'
        >
          <Typography>
            <FormattedMessage id='nav.products' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/matrixgold'>
              <FormattedMessage id='nav.matrixgold' />
            </Link>
            <Link to='/countersketch'>
              <FormattedMessage id='nav.countersketch' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel2'} onChange={() => handleChange('panel2')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel2d-content'
          id='panel2d-header'
        >
          <Typography>
            <FormattedMessage id='nav.education' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/education/matrixgold'>
              <FormattedMessage id='nav.matrixgold' />
            </Link>
            <Link to='/education/countersketch'>
              <FormattedMessage id='nav.countersketch' />
            </Link>
            <Link to='/education/educational-partners'>
              <FormattedMessage id='learning.educationalPartners' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel3'} onChange={() => handleChange('panel3')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.contact' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/tech-support'>
              <FormattedMessage id='nav.support' />
            </Link>
            <Link to='/sales'>
              <FormattedMessage id='nav.sales' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel4'} onChange={() => handleChange('panel4')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.buy' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/store'>
              <FormattedMessage id='nav.store' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel5'} onChange={() => handleChange('panel5')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.relatedSites' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to={'https://www.stuller.com/'} target='_blank' rel='noopener noreferrer'>
              Stuller.com
            </Link>

            <Link to={'https://blog.stuller.com/'} target='_blank' rel='noopener noreferrer'>
              Stuller Blog
            </Link>
            <Link to={'https://www.stuller.com/benchjeweler'} target='_blank' rel='noopener noreferrer'>
              Stuller Benchjeweler
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
    </Box>
  )
}

export default FooterMenuMobile
