import { GET_EDUCATION_PRODUCTS, GET_MEMBERSHIP_PRODUCTS, GET_EDUCATION_PRODUCTS_FAILURE } from '../actions'
import { IStripeInitialState, IStripeProduct } from '../interface'

interface IActionStripeProducts {
  type: string
  payload: {
    data: IStripeProduct[]
  }
}

const initialState: IStripeInitialState = {
  educationProducts: null,
  membershipProducts: null,
  error: false
}

export const stripeProductsReducer = (state = initialState, action: IActionStripeProducts) => {
  switch (action.type) {
    case GET_EDUCATION_PRODUCTS:
      return {
        ...state,
        educationProducts: action.payload.data,
        error: false
      }
    case GET_MEMBERSHIP_PRODUCTS:
      return {
        ...state,
        membershipProducts: action.payload.data,
        error: false
      }
    case GET_EDUCATION_PRODUCTS_FAILURE:
      return {
        ...state,
        error: true
      }
    default:
      return state
  }
}
