import { Stack } from '@mui/material'
import './NavBar.css'
import Navigation from './Navigation.json'
import MenuHorizontalButtons from './MenuHorizontalButtons'
import { styled } from '@mui/material/styles'

const MenuStack = styled(Stack)({
  gap: 4,
  flexDirection: 'row',
  alignItems: 'center',
  height: 48,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
})

export default function MenuHorizontal() {
  return (
    <MenuStack>
      {Navigation.map((navigation, index) => (
        <MenuHorizontalButtons
          key={index}
          navigationURL={navigation.url}
          navigationTitle={navigation.title}
          navigationCY={navigation.dataCy}
          navigationDropdown={navigation.dropdown}
        />
      ))}
    </MenuStack>
  )
}
