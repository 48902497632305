import React from 'react'
import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@mui/styles'
import { useCookies } from 'react-cookie'
import { updateFBCookies, updateGACookies } from '../utils/cookies'

const useStyles = makeStyles(() => ({
  cookiesBanner: {
    zIndex: 99,
    width: '100%',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#111',
    boxShadow: '0 0 20px 0 #0004'
  },
  cookiesWrapper: {
    padding: '2rem',
    maxWidth: 720,
    margin: 'auto'
  }
}))

const CookiesBanner = () => {
  const classes = useStyles()
  const [cookies, setCookie] = useCookies()
  const acceptCookies = () => {
    const expiresDate = new Date(Date.now() + 2592000000)
    setCookie('hideCookiesBanner', true, { path: '/', expires: expiresDate })
    setCookie('analysisCookies', true, { path: '/', expires: expiresDate })
    setCookie('marketingCookies', true, { path: '/', expires: expiresDate })
    setCookie('preferenceCookies', true, { path: '/', expires: expiresDate })
    updateGACookies(true)
    updateFBCookies(true)
  }
  if (cookies.hideCookiesBanner) {
    return null
  }
  return (
    <div className={classes.cookiesBanner}>
      <section className={classes.cookiesWrapper}>
        <Typography align='left'>
          <FormattedMessage id='cookies.weUseCookies' />
        </Typography>
        <div className='button-group'>
          <Button color='secondary' variant='outlined' component={Link} to='/legal/cookies'>
            <FormattedMessage id='cookies.cookiesPolicies' />
          </Button>
          <Button color='secondary' variant='outlined' component={Link} to='/legal/cookie-settings'>
            <FormattedMessage id='cookies.customizeCookies' />
          </Button>
          <Button variant='contained' color='secondary' onClick={acceptCookies}>
            <FormattedMessage id='cookies.acceptCookies' />
          </Button>
        </div>
      </section>
    </div>
  )
}

export default CookiesBanner
